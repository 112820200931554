'use strict';

var pageData = $('.js-page').data('dataLayer');
var BrTrkHandler = require('bloomreach/helperPixel');

var position = 1;
var plpPosition = 0;
var recPosition = 0;

function parseGtmData(ga4Data) {
    ga4Data.affiliation = $('.js-page').data('site');
    ga4Data.currency = pageData.currencyCode;
    if (ga4Data.metric5) {
        delete ga4Data.metric5;
    }
    if (ga4Data.dimension13) {
        // ga4Data.dimension_13 = ga4Data.dimension13;
        delete ga4Data.dimension13;
    }
    if (ga4Data.dimension14) {
        // ga4Data.dimension_14 = ga4Data.dimension14;
        delete ga4Data.dimension14;
    }
    if (ga4Data.dimension15) {
        // ga4Data.dimension_15 = ga4Data.dimension15;
        ga4Data.promotion_applied = ga4Data.dimension15;
        delete ga4Data.dimension15;
    }
    if (ga4Data.dimension17) {
        // ga4Data.dimension_17 = ga4Data.dimension17;
        delete ga4Data.dimension17;
    }
    if (ga4Data.dimension19) {
        // ga4Data.dimension_19 = ga4Data.dimension19;
        ga4Data.stock_status = ga4Data.dimension19;
        delete ga4Data.dimension19;
    }
    if (ga4Data.dimension21) {
        // ga4Data.dimension_21 = ga4Data.dimension21;
        delete ga4Data.dimension21;
    }
    if (ga4Data.dimension_23) {
        // ga4Data.dimension_23 = ga4Data.dimension_23.replace(/([a-z])([A-Z])/g, '$1 $2');
        delete ga4Data.dimension_23;
    }
    if (ga4Data.brand) {
        // ga4Data.item_brand = ga4Data.brand;
        delete ga4Data.brand;
    }

    ga4Data.item_id = ga4Data.id; delete ga4Data.id;
    ga4Data.item_name = ga4Data.name; delete ga4Data.name;
    // ga4Data.index = ga4Data.position ? ga4Data.position : 0;
    ga4Data.index = 0;
    delete ga4Data.position;
    ga4Data.item_variant = ga4Data.variant && ga4Data.variant != '' ? ga4Data.variant : ga4Data.item_id; delete ga4Data.variant;
    if (ga4Data.list === 'Search') {
        ga4Data.list = 'search results';
    }
    ga4Data.item_list_id = ga4Data.list && ga4Data.list != '' ? ga4Data.list.replace(/ /g, '_').replace(/-/g, '').toLowerCase() : '';
    ga4Data.item_list_name = ga4Data.list && ga4Data.list != '' ? ga4Data.list : '';
    delete ga4Data.list;
    ga4Data.location_id = ga4Data.location_id ? ga4Data.location_id : '';
    ga4Data.coupon = ga4Data.coupon ? ga4Data.coupon : '';
    // if (ga4Data.basePrice) {
    //     ga4Data.price = ga4Data.basePrice;
    // }
    // delete ga4Data.basePrice;
    if (ga4Data.discountValue) {
        ga4Data.discount = ga4Data.discountValue > 0 ? ga4Data.discountValue.toFixed(2) : '';
    } else {
        ga4Data.discount = ga4Data.discount ? ga4Data.discount : '';
    }
    delete ga4Data.discountValue;
    ga4Data.quantity = ga4Data.quantity ? ga4Data.quantity : 1;

    if (ga4Data.category && ga4Data.category != '') {
        var categoryArray = ga4Data.category.split('/');
        let indexKey = '';
        $.each(categoryArray, function (i) {
            if (i > 0) {
                indexKey = i + 1;
            }
            if (categoryArray[i] != '') {
                ga4Data['item_category' + indexKey] = categoryArray[i];
            }
        });
    }
    delete ga4Data.category;
    delete ga4Data.realPID;

    return ga4Data;
}

function getListValueGA4(element, listValue) {
    if ($(element).parents('.recommendations').length && $(element).parents('.recommendations').find('.recommendation-widget-title')) {
        listValue = $(element).parents('.recommendations').find('.recommendation-widget-title').text().trim();
    } else if ($(element).parents('.search-page').length) {
        listValue = 'search results';
    } else {
        listValue = formatEventString(listValue);
    }
    return listValue;
}

function getListValue(element, listValue) {
    if ($(element).parents('.search-page').length) {
        listValue = 'Search';
    } else if ($(element).parents('.cart-recommendations').length) {
        listValue = 'Cart - Recommended Products';
    } else if ($(element).parents('.recommendations').length) {
        listValue = 'PDP - Recommended Products';
    } else if ($(element).parents('.featuredProducts').length) {
        listValue = 'Featured Products';
    } else {
        listValue = formatEventString(listValue);
    }
    return listValue;
}

function impressionHandler() {
    var $products = $('.js-gtm-product:not(.js-product-detail-page)');
    var impressionsArray = [];
    $products.each(function (idx, element) {
        if (!($(element).parents('.slick-cloned').length)) {
            if ($(element).parents('.slick-slide').length) {
                var $parentElement = $(element).parents('.slick-slide');
                if (!$parentElement.hasClass('slick-active')) {
                    return;
                }
            }
            var isProductVisible = isElementInViewport(element);
            if (isProductVisible) {
                var gtmData = $(element).data('gtmProduct');
                if (gtmData.length) {
                    gtmData = gtmData[0];
                }

                $(element).attr('gtm-position', position);
                gtmData.position = position++;
                var productRating = $(element).find('.yotpo-stars .sr-only'),
                    productReviewsCount = $(element).find('.yotpo-bottomline .text-m'),
                    listValue = null;

                if (productRating.length) {
                    gtmData.dimension13 = parseFloat(formatEventString(productRating.text()), 10).toString();
                    $(element).attr('gtm-rating', productRating.text());
                }
                if (productReviewsCount.length) {
                    gtmData.dimension14 = parseInt(formatEventString(productReviewsCount.text())).toString();
                    $(element).attr('gtm-rating-count', productReviewsCount.text());
                }

                listValue = getListValue(element, gtmData.category);
                gtmData.list = listValue;
                var ga4Data = JSON.parse(JSON.stringify(gtmData));
                listValue = getListValueGA4(element, gtmData.category);
                ga4Data.list = listValue;

                if (gtmData.metric5) {
                    delete gtmData.metric5;
                }

                if (gtmData.dimension17) {
                    delete gtmData.dimension17;
                }

                if (gtmData.dimension19) {
                    delete gtmData.dimension19;
                }

                impressionsArray.push(gtmData);

                $(element).attr('gtm-list', listValue);
                $(element).removeClass('js-gtm-product');

            }
        }
    });
    if (impressionsArray.length) {
        dataLayer.push({
            event: 'productImpression',
            ecommerce: {
                currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '',
                impressions: impressionsArray
            }
        });
    }
}

function impressionHandlerGA4() {
    var productsObj = {};
    var gtmPosition;
    if ($('.product-grid').length) {
        productsObj.plp = $('.product-grid').find('.js-ga4-product');
    }
    if ($('.recommendations').length) {
        var isRecSectionVisible = isElementInViewport($('.recommendations'));
        if (isRecSectionVisible) {
            productsObj.rec = $('.recommendations').find('.js-ga4-product');
        }
    }
    for (const productKey in productsObj) {
        var ga4ImpressionsArray = [];
        var itemList = '';
        var itemListId = '';
        productsObj[productKey].each(function (idx, element) {
            if (productKey == 'plp') {
                var isProductVisible = isElementInViewport(element);
                if (!isProductVisible) {
                    return;
                }
                gtmPosition = plpPosition;
                plpPosition++;
            } else if (!($(element).parents('.slick-cloned').length)) {
                var isProductVisible = isElementInViewport($('.recommendations'));
                if (!isProductVisible) {
                    return;
                }
                gtmPosition = recPosition;
                recPosition++;
            } else {
                return;
            }
            var ga4Data = $(element).data('gtmProduct');
            if (ga4Data.length) {
                ga4Data = JSON.parse(JSON.stringify(ga4Data[0]));
            } else {
                ga4Data = JSON.parse(JSON.stringify(ga4Data));
            }

            let listValue = getListValueGA4(element, ga4Data.category);
            ga4Data.list = listValue;

            ga4Data = parseGtmData(ga4Data);
            ga4Data.index = gtmPosition;
            $(element).attr('gtm-index', gtmPosition);
            ga4ImpressionsArray.push(ga4Data);
            if (listValue != '') {
                itemList = listValue;
                itemListId = ga4Data.item_list_id;
            }
            $(element).removeClass('js-ga4-product');
            if (ga4ImpressionsArray.length == 12) {
                ga4ImpressionsPush(itemListId, itemList, ga4ImpressionsArray);
                ga4ImpressionsArray = [];
            }
        });

        if (ga4ImpressionsArray.length) {
            ga4ImpressionsPush(itemListId, itemList, ga4ImpressionsArray);
        }
    }
}

function ga4ImpressionsPush(itemListId, itemList, ga4ImpressionsArray) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            item_list_id: itemListId,
            item_list_name: itemList,
            items: ga4ImpressionsArray
        }
    });
}

function formatEventString(str) {
    return str.replace(/(?!\w|\s|-|\/|\.)./g, '')
        .replace(/\s+/g, ' ')
        .replace(/\r?\n/g, '')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
}

function isElementInViewport(el) {
    // Special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
}

function validateEmail($email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test($email);
}

function checkEmptyField() {
    $('.write-review input[aria-required=\'true\']:not(.yotpo-submit), .write-review textarea[aria-required=\'true\']').addClass('is-invalid');
    $('.write-review input[aria-required=\'true\']:not(.yotpo-submit), .write-review textarea[aria-required=\'true\']').on('blur', function () {
        if ($(this).val().trim().length && $(this).attr('name') !== 'email') {
            $(this).removeClass('is-invalid');
        } else if ($(this).val().trim().length && $(this).attr('name') == 'email') {
            if (validateEmail($(this).val())) {
                $(this).removeClass('is-invalid');
            }
        } else {
            if (!$(this).hasClass('is-invalid')) {
                $(this).addClass('is-invalid');
            }
        }
    });
}

function getQueryParams(qs) {
    if (!qs || qs.length === 0) { return {}; }
    var params = {};
    var unescapedQS = decodeURIComponent(qs);
    unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
            params[$1] = $3;
        }
    );
    return params;
}

/**
 * @function
 * @desc Determines if the device that is being used is mobile
 * @returns {boolean} - Wether or not the browser is currently mobile
 */
function isMobile() {
    var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
    var idx = 0;
    var isMobile = false;
    var userAgent = (navigator.userAgent).toLowerCase();

    while (mobileAgentHash[idx] && !isMobile) {
        isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
        idx++;
    }
    return isMobile;
}

function formErrorsHandler(formNameVariable) {
    var $formName = formNameVariable.toString(),
        isInvalidLength = $('.is-invalid:visible').length,
        $inputsName = '',
        $errorMessages = '';

    $('.is-invalid:visible').each(function (idx, element) {
        if (idx === (isInvalidLength - 1)) {
            $inputsName += $(element).attr('name');
            $errorMessages += formatEventString($(element).next('.invalid-feedback').text());

            dataLayer.push({
                event: 'formSubmit',
                formName: $formName,
                submitResult: 'fail'
            });

            dataLayer.push({
                event: 'formError',
                formName: $formName,
                errorMessage: $errorMessages,
                formErrorField: $inputsName
            });
        } else {
            $inputsName += $(element).attr('name') + '|';
            $errorMessages += formatEventString($(element).next('.invalid-feedback').text()) + '|';
        }
    });
}

function checkoutParseData(productsData) {
    var productsObject = [];
    if (productsData.length > 0) {
        productsObject = JSON.parse(JSON.stringify(productsData));
    }

    for (var i = 0; i < productsObject.length; i++) {

        var productId = '';
        var hasReview = '';

        // check if product has varient stored
        if (productsObject[i].variant != '') {
            productId = productsObject[i].variant;
            hasReview = JSON.parse(localStorage.getItem(productId));
        }

        // if no variant, check if product has master stored
        if (!hasReview) {
            productId = productsObject[i].id;
            hasReview = JSON.parse(localStorage.getItem(productId));
        }

        if (hasReview) {
            productsObject[i].dimension21 = hasReview.dimension21
            if (hasReview.productRating.length) {
                productsObject[i].dimension13 = parseFloat(formatEventString(hasReview.productRating), 10).toString();
            } else {
                productsObject[i].dimension13 = '0';
            }
            if (hasReview.productReviewsCount.length) {
                productsObject[i].dimension14 = parseInt(formatEventString(hasReview.productReviewsCount)).toString();
            } else {
                productsObject[i].dimension14 = '0';
            }
        } else {
            productsObject[i].dimension21 = '0';
            productsObject[i].dimension13 = '0';
            productsObject[i].dimension14 = '0';
        }
    }

    return productsObject;
}

function sendCheckoutData(jsPage, eventName, orderData = '') {
    var productCartData = checkoutParseData(jsPage.data('gtmCheckout'));
    if (productCartData.length > 0) {
        $(productCartData).each(function (idx, productData) {
            let listValue = localStorage.getItem(productData.id + '_list');
            if (listValue) {
                productData.list = listValue;
            }
            if (productData.dimension_23 && eventName == 'purchase2') {
                productData.fulfillment_type = productData.dimension_23.replace(/([a-z])([A-Z])/g, '$1 $2');
            }
            productData = parseGtmData(productData);
        });

        var totalValue = 0;
        if ($('#grand-total').length > 0) {
            totalValue = $('#grand-total').data('value');
        } else if ($('.grand-total-sum').length > 0) {
            totalValue = $('.grand-total-sum').html().replace('$', '').trim();
        }
        var dataObject = {
            currency: pageData.currencyCode,
            value: totalValue,
            items: productCartData
        };
        if (eventName != 'view_cart' && eventName != 'purchase2') {
            let couponCodes = '';
            if ($('.coupon-code-applied').length > 0) {
                couponCodes = $('.coupon-code-applied').map(
                    function () {
                        return $(this).data('coupon-code');
                    }
                ).get().join('|');
            } else if (jsPage.data('coupons') && jsPage.data('coupons') != '') {
                couponCodes = jsPage.data('coupons');
            }
            dataObject.coupon = couponCodes;
        }
        if (eventName == 'add_shipping_info' && $('.shipping-method-list').find('.form-check-input:checked').length > 0) {
            const selectedShipMethod = $('.shipping-method-list').find('.form-check-input:checked');
            if (selectedShipMethod.siblings('label.shipping-method-option').length > 0) {
                let shippingTier = selectedShipMethod.siblings('label.shipping-method-option').find('.display-name');
                dataObject.shipping_tier = shippingTier ? shippingTier.html().trim() : '';
            }
        }
        if (eventName == 'add_payment_info' && $('.payment-details').length > 0) {
            let paymentMethod = $('.payment-details').find('.paymentMethod');
            dataObject.payment_type = paymentMethod.length > 0 ? $(paymentMethod[0]).text().trim() : '';
        }
        if (eventName == 'purchase2') {
            dataObject.transaction_id = orderData.id;
            dataObject.value = orderData.revenue;
            dataObject.tax = orderData.tax;
            dataObject.shipping = orderData.shipping;
            dataObject.coupon = orderData.coupon;
            dataObject.coupon_type = orderData.couponType;
            dataObject.discount_amount = orderData.discountAmount;
            dataObject.logged_in = orderData.loggedIn;
            dataObject.payment_type = orderData.paymentType;
            dataObject.loyalty_flag = orderData.loyaltyFlag;
            dataObject.shipping_method = orderData.shippingMethod;
            dataObject.loan_flag = orderData.paymentType == 'Affirm' ? 'true' : 'false';
            dataObject.sfid = orderData.customerNo;
        }
        const ecommerceData = {
            event: eventName,
            ecommerce: dataObject
        };
        dataLayer.push(ecommerceData);
    }
}

module.exports = {
    initDataLayer: function () {
        window.dataLayer = window.dataLayer || [];
    },

    global: function () {
        if (pageData) {
            let globalData = {
                event: 'global',
                pageType: pageData.pageType,
                pageName: pageData.pageName,
                poolBrand: pageData.poolBrand,
                loggedIn: pageData.loggedIn,
                sCode: 'WYAARUNK',
                hashedEmail: pageData.hashedEmail,
                pageBreadcrumb: pageData.pageBreadcrumb,
                currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '',
            };
            if (window.resources && window.resources.gtm != undefined && window.resources.gtm.userId != undefined && window.resources.gtm.userId != '') {
                globalData.userId = window.resources.gtm.userId;
            }
            dataLayer.push(globalData);
        }
    },

    productClick: function () {
        $('body').on('click', '.js-gtm-product-tile a', function (e) {
            const productTile = $(this).parents('.js-gtm-product-tile');
            var product = $(productTile).data('gtmProduct');

            if ($(productTile).attr('gtm-list')) {
                var list = formatEventString($(productTile).attr('gtm-list'));
            }

            if (product && $(productTile).attr('gtm-position')) {
                product.position = parseInt(formatEventString($(productTile).attr('gtm-position')));
            }

            if (product && $(productTile).attr('gtm-rating')) {
                product.dimension13 = parseFloat(formatEventString($(productTile).attr('gtm-rating')), 10).toString();
            }

            if (product && $(productTile).attr('gtm-rating-count')) {
                product.dimension14 = parseInt(formatEventString($(productTile).attr('gtm-rating-count'))).toString();
            }

            var ga4Data = JSON.parse(JSON.stringify(product));

            if (product.metric5) {
                delete product.metric5;
            }

            if (product.dimension17) {
                delete product.dimension17;
            }

            if (product.dimension19) {
                delete product.dimension19;
            }

            // if (product.list) {
            //     delete product.list;
            // }

            dataLayer.push({
                event: 'productClick',
                ecommerce: {
                    currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '',
                    click: {
                        actionField: { list: list },
                        products: [product]
                    }
                }
            });

            if (ga4Data.id !== undefined) {
                let listValue = getListValueGA4(productTile, ga4Data.category);
                ga4Data.list = listValue;
                localStorage.setItem(ga4Data.id + '_list', listValue);
                // GA4
                ga4Data = parseGtmData(ga4Data);
                ga4Data.index = parseInt(formatEventString($(productTile).attr('gtm-index')));
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'select_item',
                    ecommerce: {
                        item_list_id: ga4Data.item_list_id,
                        item_list_name: ga4Data.item_list_name,
                        items: [ga4Data]
                    }
                });
            }
        });
    },

    productDetailView: function () {
        var $pdp = $('.js-product-detail-page'),
            $spaBuilder = $('.js-scb-form');
        // Do not fire on spa cover builder
        if ($pdp.is('*') && !$spaBuilder.length) {
            var productDetail = JSON.parse($pdp.attr('data-gtm-product')),
                productRating = $('.bottom-line-items .avg-score'),
                productReviewsCount = $('.reviews-qa-label');

            if (productRating.length) {
                productDetail[0].dimension13 = parseFloat(formatEventString(productRating.text()), 10).toString();
            } else {
                productDetail[0].dimension13 = '0';
            }
            if (productReviewsCount.length) {
                productDetail[0].dimension14 = parseInt(formatEventString(productReviewsCount.text())).toString();
            } else {
                productDetail[0].dimension14 = '0';
            }

            var ga4Data = JSON.parse(JSON.stringify(productDetail[0]));
            // ga4Data.dimension_23 = $('input[name="deliveryMethod"]:checked').val();
            if ($('.promotions .callout').length > 0) {
                ga4Data.coupon = $('.promotions .callout').map((i, el) => el.innerText.trim()).get().join('|');
            }
            if (productDetail[0].list) {
                ga4Data.list = productDetail[0].list;
                delete productDetail[0].list;
            }

            if (productDetail[0].position) {
                delete productDetail[0].position;
            }
            dataLayer.push({
                event: 'eec.detail',
                ecommerce: {
                    currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '',
                    detail: {
                        products: productDetail
                    }
                }
            });
            let listValue = localStorage.getItem(ga4Data.id + '_list');
            if (listValue) {
                ga4Data.list = listValue;
            }
            ga4Data = parseGtmData(ga4Data);
            // GA4
            dataLayer.push({
                event: 'view_item',
                ecommerce: {
                    currency: ga4Data.currency,
                    value: productDetail[0].price,
                    items: [ga4Data]
                }
            });
        }
    },

    quickview: function () {
        $('body').on('click', '.js-quick-view', function () {
            var product = $(this).data('gtmProduct');
            var $productTile = $(this).parents('.js-gtm-product-tile');
            var productRating = $productTile.find('.yotpo-bottomline .yotpo-stars .sr-only');
            var productReviewsCount = $productTile.find('.yotpo-bottomline .text-m');

            if (productRating.length) {
                product.dimension13 = parseFloat(formatEventString(productRating.text()), 10).toString();
            } else {
                product.dimension13 = '0';
            }
            if (productReviewsCount.length) {
                product.dimension14 = parseInt(formatEventString(productReviewsCount.text())).toString();
            } else {
                product.dimension14 = '0';
            }

            if (productReviewsCount.length && productRating.length) {
                var productID = product.id;
                var reviewItem = { 'productRating': productRating.text(), 'productReviewsCount': productReviewsCount.text() };
                sessionStorage.setItem(productID, JSON.stringify(reviewItem));
            }

            dataLayer.push({
                event: 'eec.detail',
                ecommerce: {
                    currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '',
                    detail: {
                        products: [product]
                    }
                }
            });
        });

    },

    aboveGroundPoolView: function () {
        var $aboveGroundPool = $('.js-above-ground-pool');
        if ($aboveGroundPool.is('*')) {
            var $pools = $('.js-gtm-pool');
            var firstPoolData = $pools.eq(0).data('gtmPool');

            dataLayer.push({
                event: 'eec.detail',
                currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '', //include 3 letter currency code for site
                ecommerce: {
                    detail: {
                        actionField: { affiliation: pageData.affiliation },
                        products: firstPoolData
                    }
                }
            });
            dataLayer.push({
                ecommerce: {
                    currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '', // Local currency is optional.
                    impressions: firstPoolData.map(function (pool, index) {
                        return pool;
                    })
                }
            });
            $pools.on('show.bs.collapse', function () {
                dataLayer.push({
                    event: 'eec.detail',
                    currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '', //include 3 letter currency code for site
                    ecommerce: {
                        detail: {
                            actionField: { affiliation: pageData.affiliation },
                            products: $(this).data('gtmPool')
                        }
                    }
                });
                dataLayer.push({
                    ecommerce: {
                        currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '', // Local currency is optional.
                        impressions: $(this)
                            .data('gtmPool')
                            .map(function (pool, index) {
                                return pool;
                            })
                    }
                });
            });
        }
    },

    productImpressions: function () {
        $(window).on('DOMContentLoaded load resize scroll', () => { impressionHandler(), impressionHandlerGA4() });
        $(document).on('afterChange', '.js-product-tile-carousel', impressionHandler);
        $(document).on('afterChange', '.js-recommendation-carousel', impressionHandler);
    },

    addToCart: function () {
        $('body').on('product:afterAddToCart', function (e, data) {
            var products = data.gtmAddToCartData;
            var productID = products[0].realPID || products[0].id;
            var hasReview = JSON.parse(sessionStorage.getItem(productID));
            var $hasQuickBuy = $('.js-quick-buy-sku');
            products[0].dimension13 = '0';
            products[0].dimension14 = '0';
            products[0].dimension21 = '0';

            if (window.resources.bloomreach.pixel === 'true') {
                var pdpSKU = $('span.manufacturer-id').first().text();
                var plpSKU = $('small.text-muted').text().replace('SKU #: ', '')
                var prodObj = {
                    prod_id: productID,
                    sku: pdpSKU.length > 0 ? pdpSKU : plpSKU
                }

                BrTrkHandler.brtrkHandle(prodObj, 'Event', 'cart', 'click-add');
            }

            if ($hasQuickBuy.is('*')) {
                var hasQuickBuyCondition = $('.js-quick-buy-sku').val().length;
            } else {
                hasQuickBuyCondition = 0;
            }

            if ($(e.target).find('.add-to-cart').length > 0 && hasQuickBuyCondition == 0) {
                products[0].dimension21 = "pdp";
            } else if ($(e.target).find('.add-to-cart-global').length > 0 && $('.schematic-product-detail').length > 0) {
                products[0].dimension21 = "schematic";
            } else if ($(e.target).find('.add-to-cart-global').length > 0) {
                products[0].dimension21 = "quickview";
            } else {
                products[0].dimension21 = "quickorder";
            }

            if ($('.write-review-btn-hidden').length == 0 && $('.js-product-detail-page').length > 0) {
                var productRating = $('.bottom-line-items .avg-score');
                var productReviewsCount = $('.reviews-qa-label');
                var productID = $('.product-id').text();
                var reviewItem = { 'productRating': productRating.text(), 'productReviewsCount': productReviewsCount.text() };
                localStorage.setItem(productID, JSON.stringify(reviewItem));
                products[0].dimension13 = parseFloat(formatEventString(productRating.text()), 10).toString();
                products[0].dimension14 = parseInt(formatEventString(productReviewsCount.text())).toString();
            }

            if (hasReview) {
                products[0].dimension13 = parseFloat(formatEventString(hasReview.productRating), 10).toString();
                products[0].dimension14 = parseInt(formatEventString(hasReview.productReviewsCount)).toString();
                localStorage.setItem(productID, JSON.stringify(hasReview));
                sessionStorage.removeItem(productID);
            }

            var hasProductId = localStorage.getItem(productID)
            if (hasProductId) {
                var hasProductIdObj = JSON.parse(hasProductId);
                hasProductIdObj.dimension21 = products[0].dimension21
                localStorage.setItem(productID, JSON.stringify(hasProductIdObj))
            } else {
                var customDimention21 = { 'dimension21': products[0].dimension21, 'productRating': '0', 'productReviewsCount': '0' }
                localStorage.setItem(productID, JSON.stringify(customDimention21));
            }

            var ga4Data = JSON.parse(JSON.stringify(products[0]));
            // ga4Data.dimension_23 = $('input[name="deliveryMethod"]:checked').val();
            if ($('.promotions .callout').length > 0) {
                ga4Data.coupon = $('.promotions .callout').map((i, el) => el.innerText.trim()).get().join('|');
            }

            if (products) {
                dataLayer.push({
                    event: 'addToCart',
                    ecommerce: {
                        currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '',
                        add: {
                            products: products
                        }
                    }
                });

                let listValue = localStorage.getItem(ga4Data.id + '_list');
                if (listValue) {
                    ga4Data.list = listValue;
                }
                ga4Data = parseGtmData(ga4Data);
                if ($('.omni-availability-container').length > 0 && $('.omni-availability-container').data('deliveryMethods')) {
                    ga4Data.location_id = $('.omni-availability-container').data('deliveryMethods');
                }
                // GA4
                dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: ga4Data.currency,
                        value: products[0].price,
                        items: [ga4Data]
                    }
                });
            }
        });
    },

    removeFromCart: function () {
        $('body').on('cart:removal', function (e, data) {
            var products = checkoutParseData(data.gtmRemovedFromCart);
            dataLayer.push({
                event: 'removeFromCart',
                ecommerce: {
                    currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '',
                    remove: {
                        products: products
                    }
                }
            });
            products = parseGtmData(products[0]);
            // GA4
            var dataObject = {
                event: 'remove_from_cart',
                ecommerce: {
                    currency: pageData.currencyCode,
                    value: products.price,
                    items: [products]
                }
            };
            dataLayer.push(dataObject);

            var $cartPage = $('.js-cart-page');
            if ($cartPage.is('*')) {
                var productsObject = JSON.parse(JSON.stringify($cartPage.data('gtmCheckout')));
                var cartProducts = productsObject.filter(function (product) { return product.id != products.item_id });
                $cartPage.data('gtmCheckout', cartProducts);
            }
        });
    },

    cartQuantityChange: function () {
        $('body').on('cart:quantityUpdate', function (e, data) {
            var products = [data.gtmQuantityChange.product];
            if (data.gtmQuantityChange.type === 'removal') {
                dataLayer.push({
                    event: 'enhanceEcom productRemoveFromCart',
                    currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '', //include 3 letter currency code for site
                    ecommerce: {
                        remove: {
                            actionField: { affiliation: pageData.affiliation },
                            products: products
                        }
                    }
                });
            } else if (data.gtmQuantityChange.type === 'addition') {
                if (products) {
                    dataLayer.push({
                        event: 'enhanceEcom productAddToCart',
                        currencyCode: pageData && pageData.currencyCode ? pageData.currencyCode : '', //include 3 letter currency code for site
                        ecommerce: {
                            add: {
                                actionField: {
                                    affiliation: pageData.affiliation
                                },
                                products: products
                            }
                        }
                    });
                }
            }
            var $cartPage = $('.js-cart-page');
            if ($cartPage.is('*')) {
                var productsObject = JSON.parse(JSON.stringify($cartPage.data('gtmCheckout')));
                for (const i in productsObject) {
                    if (productsObject[i].id === products[0].id) {
                        productsObject[i] = products[0];
                        break;
                    }
                }
                if (productsObject.length > 0) {
                    $cartPage.data('gtmCheckout', productsObject);
                }
            }
        });
    },

    cart: function () {
        var $cartPage = $('.js-cart-page');
        if ($cartPage.is('*')) {
            sendCheckoutData($cartPage, 'view_cart');
        }
    },

    checkout_begin: function () {
        $('body').on('checkout:begin', function () {
            var $cartPage = $('.js-cart-page');
            if ($cartPage.is('*')) {
                sendCheckoutData($cartPage, 'begin_checkout');
            }
        });
    },

    checkout: function () {
        var $checkoutPage = $('.js-checkout-page');
        var currentUrl = getQueryParams(window.location.href);
        if ($checkoutPage.is('*')) {
            var dataObject = {
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: $checkoutPage.data('gtmCheckoutStep'),
                            option: $checkoutPage.data('customerType')
                        }
                    }
                }
            };
            var productCheckoutData = [];
            if ($checkoutPage.data('gtmFirstStep')) {
                productCheckoutData = checkoutParseData($checkoutPage.data('gtmCheckout'));

                if (currentUrl.registration !== 'false') {
                    dataObject.ecommerce.checkout.products = productCheckoutData;
                }
            }

            dataLayer.push(dataObject);
            $checkoutPage.removeAttr('data-gtm-first-step');
        }
    },

    checkoutStep: function () {
        $('body').on('checkout:updateCheckoutView', function (e, data) {
            var $checkoutPage = $('.js-checkout-page');
            var step = data.order.steps.billing.iscompleted
                ? data.order.billing.payment.selectedPaymentInstruments.length > 0
                    ? 4
                    : 3
                : 2;
            if (step != 2) {
                dataLayer.push({
                    event: 'checkout',
                    ecommerce: {
                        checkout: {
                            actionField: {
                                step: step,
                                option: $checkoutPage.data('customerType')
                            }
                        }
                    }
                });
            }
        });
    },

    handleNextStage: function () {
        $('body').on('checkout:handleNextStage', function (e, stage) {
            var $checkoutPage = $('.js-checkout-page');

            if (stage == 'payment') {
                sendCheckoutData($checkoutPage, 'add_shipping_info');
            } else if (stage == 'placeOrder') {
                sendCheckoutData($checkoutPage, 'add_payment_info');
            }
        });
    },

    transaction: function () {
        var orderType = "standard";
        var $orderConfirmation = $('.js-gtm-order-confirm');

        if ($orderConfirmation.is('*')) {
            var order = $orderConfirmation.data('gtmOrder');
            var products = checkoutParseData($orderConfirmation.data('gtmCheckout'));

            if (order.buyNowOrder) {
                orderType = 'instant';

                let deliveryMethod = '';
                if ($('.delivery-selection-label').length > 0) {
                    deliveryMethod = $('.delivery-selection-label').text().trim();
                }
                let productId = products[0].realPID || products[0].id;
                const gtmData = {
                    'event': 'instantCheckoutComplete',
                    'productId': productId,
                    'unitPrice': products[0].price,
                    'deliverySelection': deliveryMethod
                };
                dataLayer.push(gtmData);
            }

            order.orderType = orderType;
            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    purchase: {
                        actionField: order,
                        products: products
                    }
                }
            });
            sendCheckoutData($orderConfirmation, 'purchase2', order);
        }
    },

    subscribe: function () {
        var currentUrl = getQueryParams(window.location.href);
        $('.submit-payment').on('click', function (e) {
            var $subscribeIsChecked = $('#add-to-email-list-checkout:checkbox:checked').length > 0;
            if ($subscribeIsChecked) {
                dataLayer.push({
                    event: 'emailSignup',
                    signupLocation: 'checkout'
                });
            }
        });

        $('body').on('email:subscribeSuccess', function () {
            dataLayer.push({
                event: 'emailSignup',
                signupLocation: 'footer'
            })
        });

        $('form.registration').submit(function (e) {
            var $subscribeIsChecked = $('#add-to-email-list:checkbox:checked').length > 0;
            if ($subscribeIsChecked) {
                localStorage.setItem("subscribeIsChecked", "true");
            }
        });

        if ($('#accountNav').is('*') && (currentUrl.registration !== 'false')) {
            var subscribe = localStorage.getItem("subscribeIsChecked");
            if (subscribe) {
                dataLayer.push({
                    event: 'emailSignup',
                    signupLocation: 'createAccount'
                });
                localStorage.removeItem("subscribeIsChecked")
            }
        }
    },

    liveChatClick: function () {
        $('.js-live-chat').on('click', function () {
            dataLayer.push({
                chatCTA: 'chat now', //the value on the button that was clicked - chat now, start chat, etc
                pageName: pageData.pageName, //pageName from the global event
                event: 'liveChat'
            });
        });
    },

    createAccountLocation: function () {

        $('.js-gtm-login-header').on('click', function (e) {
            localStorage.setItem("registrationLocation", "header");
        });

        $("div.js-checkout-page a").click(function (e) {
            localStorage.setItem("registrationLocation", "checkout");
        });

        $('form.checkout-registration').submit(function (e) {
            localStorage.setItem("registrationLocation", "orderConfirmation");
        });
    },

    createAccount: function () {
        var $accountNav = $('#accountNav');
        var currentUrl = getQueryParams(window.location.href);
        var $registrationLocation = localStorage.getItem("registrationLocation");

        if ($accountNav.is('*') && (currentUrl.registration !== 'false')) {
            if ($registrationLocation) {
                dataLayer.push({
                    event: 'accountCreated',
                    accountCreationLocation: $registrationLocation
                });
                localStorage.removeItem("registrationLocation")
            }
        }
    },

    createAccountError: function () {
        $('body').on('register:error', function () {
            formErrorsHandler('newAccount');
        });
    },

    customFormsError: function () {
        $('body').on('customForm:error', function () {
            formErrorsHandler(window.location.href);
        });
    },

    storeFrontFormErrors: function () {
        var form = $('body').find('form');
        if (form) {
            $('input, textarea').each(function () {
                if ($(this).attr('required')) {
                    $(this).addClass('form-control').parent('div').addClass('required');
                }
            })
        }

        $('body').on('click', '.btn-primary, .btn[name="submit"]', function () {
            var $currentForm = $(this).parents('form'),
                $formName = '',
                isInvalidLength = $currentForm.find('.required:visible .form-control').length;
            if ($($currentForm).hasClass('.registration')) {
                $formName = 'newAccount';
            } else {
                $formName = window.location.href
            }

            if ($currentForm.length > 0 && !$($currentForm)[0].checkValidity()) {
                var $inputsName = '',
                    $errorMessages = '';
                $($currentForm).find('.required:visible .form-control').each(function (idx, element) {
                    if (!$(element)[0].checkValidity()) {
                        $inputsName += $(element).attr('name') + '|';
                        $errorMessages += $(element)[0].validationMessage + '|';
                    }

                    if (idx === (isInvalidLength - 1)) {
                        $inputsName = $inputsName.slice(0, -1);
                        $errorMessages = $errorMessages.slice(0, -1);
                        dataLayer.push({
                            event: 'formSubmit',
                            formName: $formName,
                            submitResult: 'fail'
                        });
                        dataLayer.push({
                            event: 'formError',
                            formName: $formName,
                            errorMessage: $errorMessages,
                            formErrorField: $inputsName
                        });
                    }
                });
            }
        });
    },

    filterCartridgeRefinementsError: function () {
        $('body').on('filterCartridgeRefinements:submit', function () {
            if ($('.product-grid .col-6').length == 0) {
                dataLayer.push({
                    event: 'formSubmit',
                    formName: window.location.href,
                    submitResult: 'fail'
                });
                dataLayer.push({
                    event: 'formError',
                    formName: window.location.href,
                    errorMessage: "We are sorry, but no results were found",
                    formErrorField: "partNumberSearch"
                });
            }
        });
    },


    spacoverbuilderFormError: function () {
        $('body').on('spacoverbuilderForm:error', function () {
            formErrorsHandler(window.location.href);
        });
    },

    storeLocatorFormError: function () {
        $('body').on('storeLocator:error', function () {
            formErrorsHandler(window.location.href);
        });
    },

    emailSubscribeFormError: function () {
        $('body').on('email:subscribeError', function () {
            var $errorMessage = $('.email-signup').attr('data-error');
            dataLayer.push({
                event: 'formSubmit',
                formName: 'Footer Sign-up',
                submitResult: 'fail'
            });
            dataLayer.push({
                event: 'formError',
                formName: 'Footer Sign-up',
                errorMessage: $errorMessage,
                formErrorField: "hpEmailSignUp"
            });
        });
    },

    seeSavingsFormError: function () {
        $('body').on('seeSavings:error', function () {
            var $formName = window.location.href;
            dataLayer.push({
                event: 'formSubmit',
                formName: $formName,
                submitResult: 'fail'
            });
            dataLayer.push({
                event: 'formError',
                formName: $formName,
                errorMessage: 'Please choose your state and try again!',
                formErrorField: "stateSelect"
            });
        });
    },

    loginClick: function () {
        $('.js-gtm-login-header').on('click', function (e) {
            localStorage.setItem("loginLocation", "header");
        });

        $('.js-gtm-login-footer').on('click', function (e) {
            localStorage.setItem("loginLocation", "footer");
        });

        $('.js-checkout-page form[name="login-form"] .btn-primary').on('click', function (e) {
            localStorage.setItem("loginLocation", "checkout");
        });
    },

    loginEvent: function () {
        var $loginLocation = localStorage.getItem("loginLocation");
        var currentUrl = getQueryParams(window.location.href);

        if ($loginLocation && (currentUrl.registration === 'false')) {
            dataLayer.push({
                event: 'accountSignIn',
                signInLocation: $loginLocation
            });

            localStorage.removeItem("loginLocation");
        }
    },

    flyoutNavClicks: function () {
        $('.js-gtm-nav-top').on('click', function () {
            dataLayer.push({
                menuCategory: $(this).data('gtmCatName'), //the flyout menu category
                event: 'flyoutClick'
            });
        });
        $('.js-gtm-nav-sub').on('click', function () {
            dataLayer.push({
                menuSubcategory: $(this).data('gtmCatName'), //flyout menu subcategory (leave blank if the visitor clicks on a category flyout)
                menuCategory: $(this).parents('.js-gtm-nav-wrapper').find('.js-gtm-nav-top').data('gtmCatName'), //the flyout menu category
                event: 'flyoutClick'
            });
        });
        $('.js-gtm-nav-tert').on('click', function () {
            dataLayer.push({
                menuSelection: $(this).data('gtmCatName'), //the third level of the menu navigation - leave blank if the visitor selected a Category or SubCategory link
                menuSubcategory: $(this).parents('.js-gtm-nav-sub-wrapper').find('.js-gtm-nav-sub').data('gtmCatName'), //flyout menu subcategory (leave blank if the visitor clicks on a category flyout)
                menuCategory: $(this).parents('.js-gtm-nav-wrapper').find('.js-gtm-nav-top').data('gtmCatName'), //the flyout menu category
                event: 'flyoutClick'
            });
        });
    },

    searchPage: function () {
        $('.container').on(
            'click',
            '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button', function () {
                var facetCategory = $($(this).parents('.card').find('.card-header h2').get(0)).text();
                var facetSelection = $($(this).find('span').get(0)).text();
                dataLayer.push({
                    event: 'facetClicks',
                    facetCategory: formatEventString(facetCategory),
                    facetLocation: 'left',
                    facetSelection: formatEventString(facetSelection)
                });
            });
        $(document).on('topBar:filter', function (e) {
            var $target = $(e.target);
            var facetCategory = $($target.parents('.form-group').find('label').get(0)).text();
            var facetSelection = $target.is('select') ? $target.find('option:selected').text() : $target.parent().find('input').val();
            dataLayer.push({
                event: 'facetClicks',
                facetCategory: formatEventString(facetCategory),
                facetLocation: 'top',
                facetSelection: formatEventString(facetSelection)
            });
        });
        $(document).ready(function () {
            var searchEvent = sessionStorage.getItem('searchEvent');
            if (searchEvent) {
                searchEvent = JSON.parse(searchEvent);
                if ($('.search-result-count').length) {
                    searchEvent.numSearchResults = parseInt($($('.search-result-count').get(0)).text().replace(/[^0-9]/g, '')) || 0;
                }
                dataLayer.push(searchEvent);
                sessionStorage.removeItem('searchEvent');
            }
        });
        $(document).on('click', '.suggestions a', function () {
            var suggestionType = null;
            var $target = $(this);
            var suggestionText = $(this).text();
            if ($target.hasClass('suggested-phrase')) {
                suggestionType = 'Suggested Phrase';
            } else if ($target.hasClass('product-suggestion')) {
                suggestionType = 'Product Suggestion';
            } else if ($target.hasClass('category-suggestion')) {
                suggestionType = 'Category Suggestion';
            } else if ($target.hasClass('recent-phrase-suggestion')) {
                suggestionType = 'Recent Phrase Suggestion';
            } else if ($target.hasClass('popular-phrase-suggestion')) {
                suggestionType = 'Popular Phrase Suggestion'
            } else if ($target.hasClass('brand-suggestion')) {
                suggestionType = 'Brand Suggestion';
            } else if ($target.hasClass('content-suggestion')) {
                suggestionType = 'Content Suggestion'
            }

            var searchEvent = {
                event: 'search',
                searchType: formatEventString(suggestionType),
                searchKeyword: formatEventString(suggestionText),
                numSearchResults: 1 // adding default value for product suggestion
            };

            var productTab = $('.search-tab').find('.product')
            var contentTab = $('.search-tab').find('.content')
            var catalogs = []
            if (productTab.length > 0) {
                catalogs.push({ "name": "lesliespool" })
            }
            if (contentTab.length > 0) {
                catalogs.push({ "name": "content_en" })
            }

            if (window.resources.bloomreach.pixel === 'true') {
                var searchData = {};
                searchData["aq"] = $('.search .search-field').val() || $('.search-mobile .search-field').val();
                searchData["q"] = suggestionText.replaceAll('  ', '').replaceAll('\n', '');
                searchData["catalogs"] = catalogs;

                BrTrkHandler.brtrkHandle(searchData, 'Event', 'suggest', 'click');
            }

            sessionStorage.setItem('searchEvent', JSON.stringify(searchEvent));
        });
        $(document).on('submit', 'form[name="simpleSearch"]', function () {
            var searchKeyword = null;
            if (!isMobile()) {
                searchKeyword = $('.search .search-field').val();
            } else {
                searchKeyword = $('.search-mobile .search-field').val();
            }

            var searchEvent = {
                event: 'search',
                searchType: 'user',
                searchKeyword: searchKeyword
            };

            sessionStorage.setItem('searchEvent', JSON.stringify(searchEvent));
        });
    },

    blogEvents: function () {
        $(document).on('click', '#blogNav .blog-nav-link', function () {
            var linkValue = formatEventString($(this).text()).trim().toLowerCase();
            dataLayer.push({
                event: 'blogNavigation',
                blogSelection: linkValue,
                blogClickType: 'left navigation'
            });
        });
        $(document).on('click', '.blog-link', function () {
            var linkValue = $(this).find('.blog-title').text();
            dataLayer.push({
                event: 'blogNavigation',
                blogSelection: formatEventString(linkValue).trim().toLowerCase(),
                blogClickType: 'article click'
            })
        });

        $(document).on('click', '.blog-post-global a', function () {
            var linkValue = $(this).text();
            dataLayer.push({
                event: 'blogNavigation',
                blogSelection: formatEventString(linkValue).trim().toLowerCase(),
                blogClickType: 'article click'
            })
        });
    },

    sortSelectClick: function () {
        var currentValueSelected = formatEventString($('select[name="sort-order"]').children('option:selected').text()).toLowerCase();
        $('select[name="sort-order"]').on('change', function () {
            var newValueSelected = formatEventString($(this).children('option:selected').text()).toLowerCase();
            dataLayer.push({
                event: 'sortClick',
                sortSelection: newValueSelected,
                originalSort: currentValueSelected
            });
            currentValueSelected = newValueSelected;
        });
    },

    findInStore: function () {
        $(document).on('click', '.js-get-in-store-inventory', function () {
            var productID = $('.product-id').text();
            dataLayer.push({
                event: 'findInStore',
                findInStoreProduct: productID
            });
        });
    },

    oneDayDelivery: function () {
        $('body').on('oneDayUpdate', function (e, data) {
            dataLayer.push({
                oneDayType: data.oneDayEligibility ? 'zipcode' : 'use location',
                zipCode: data.oneDayPostalCode,
                event: 'oneDay'
            });
        });
    },

    askQuestionEvents: function () {
        $(document).on('click', '.write-question-button', function () {
            var productID = $('.product-id').text();
            dataLayer.push({
                event: 'askQuestion',
                questionProduct: formatEventString(productID),
                questionCTA: 'ask a question'
            });
            $(document).off('click', '.write-question-button'); //@TODO I added this, as Im not sure if we need to trigger same event multiple times, in case if user just keep spamming button
        });
        $(document).on('click', '.write-question-wrapper .yotpo-submit', function () {
            var productID = $('.product-id').text();
            dataLayer.push({
                event: 'askQuestion',
                questionProduct: formatEventString(productID),
                questionCTA: 'post'
            });
        });
    },

    exitLinksClick: function () {
        var noTrackDomains = ['blog.poolsupplyworld.com', 'blog.intheswim.com', 'www.lesliespool.com', window.location.host];
        $(document).on('click', 'a[href]:not([href=""]):not([href^="#"]):not([href^="/"]):not([href^="j"]):not([href^="' + window.location.protocol + '//' + window.location.host + '"])', function () {
            var anchorHref = $(this).attr('href');
            if (anchorHref) {
                var linkHref = new URL(anchorHref);
                if (!noTrackDomains.includes(linkHref.hostname)) {
                    dataLayer.push({
                        event: 'exitLink',
                        linkDomain: linkHref.hostname,
                        linkCTA: formatEventString($(this).text())
                    });
                }
            }
        });
    },

    footerEvents: function () {
        $(document).on('click', '.footer-item-links a', function () {
            var footerCategory = $(this).parents('.footer-item-links').find('h2.title').text();
            dataLayer.push({
                event: 'footerClick',
                footerSelection: formatEventString($(this).text()).toLowerCase(),
                footerCategory: formatEventString(footerCategory).toLowerCase()
            });
        });
    },

    reviewsInteractions: function () {
        $(document).on('click', '.write-review-button', function () {
            var reviewSelection = formatEventString($(this).text());
            var productID = formatEventString($('.product-id').text());
            dataLayer.push({
                event: 'reviewInteraction',
                reviewLocation: 'bottom of PDP',
                reviewSelection: reviewSelection,
                reviewProduct: productID
            });
        });

        $(document).on('click', '#yotpo-bottomline-top-div', function () {
            var reviewSelection = 'Read';
            var productID = formatEventString($('.product-id').text());
            dataLayer.push({
                event: 'reviewInteraction',
                reviewLocation: 'top of PDP',
                reviewSelection: reviewSelection,
                reviewProduct: productID
            });
        });

        $(document).on('DOMSubtreeModified', '.dropdown-filters-container', function (e) {
            var $targetElement = $(e.target);
            if ($targetElement.hasClass('filters-dropdown')) {
                var reviewSelection = (formatEventString($targetElement.data('defaultButtonDisplayValue')) + ': ' + formatEventString($targetElement.get(0).dataset.value));
                var productID = formatEventString($('.product-id').text());
                dataLayer.push({
                    event: 'reviewInteraction',
                    reviewLocation: 'bottom of PDP',
                    reviewSelection: reviewSelection,
                    reviewProduct: productID
                });
            }
        });
    },

    reviewComplete: function () {
        var productID = $('.product-id').text();
        $(document).on('click', '.write-first-review-button, .write-review-button', function () {
            checkEmptyField();
            sessionStorage.setItem("addReview", "true");
        });

        $(document).on('click', '.write-review .yotpo-submit', function () {
            var notValid = $('.write-review').find('input:visible.is-invalid, textarea:visible.is-invalid, input:visible.error').length;
            var reviewStar = $('.write-review .yotpo-header .stars-wrapper').find('.yotpo-icon-star').length;
            var reviewSession = sessionStorage.getItem("addReview");

            if (!notValid && reviewStar && reviewSession) {
                dataLayer.push({
                    event: 'reviewComplete',
                    reviewProduct: formatEventString(productID),
                    reviewStars: $('.write-review .yotpo-header .stars-wrapper').find('.yotpo-icon-star:last').data('score')
                });
                sessionStorage.removeItem("addReview");
            }
        });
    },

    variantChange: function () {
        $('body').on('product:afterAttributeSelect', function (e, data) {
            if (data.isQty) {
                return;
            }
            if (data.data.gtmProductData) {
                var gtmData = JSON.parse(data.data.gtmProductData);
                var $productTile = $(this).parents('.js-gtm-product-tile');
                var productRating = $('.bottom-line-items .avg-score').length ?
                    $('.bottom-line-items .avg-score') :
                    $productTile.find('.yotpo-bottomline .yotpo-stars .sr-only');
                var productReviewsCount = $('.reviews-qa-label').length ?
                    $('.reviews-qa-label') :
                    $productTile.find('.yotpo-bottomline .text-m');

                gtmData.detail.products.forEach(function (productData) {
                    if (productRating.length) {
                        productData.dimension13 = parseFloat(formatEventString(productRating.text()), 10).toString();
                    } else {
                        productData.dimension13 = '0';
                    }
                    if (productReviewsCount.length) {
                        productData.dimension14 = parseInt(formatEventString(productReviewsCount.text())).toString();
                    } else {
                        productData.dimension14 = '0';
                    }
                });


                dataLayer.push(Object.assign({ event: 'eec.detail' }, { ecommerce: gtmData }));
            }
        });
    },

    footerSocialShare: function () {
        $('.footer-social a').on('click', function () {
            var $sharePlatform = null;
            if ($(this).hasClass('fa-instagram')) {
                $sharePlatform = 'instagram';
            } else if ($(this).hasClass('fa-facebook-square')) {
                $sharePlatform = 'facebook';
            } else if ($(this).hasClass('fa-twitter-square')) {
                $sharePlatform = 'twitter';
            } else if ($(this).hasClass('fa-youtube-square')) {
                $sharePlatform = 'youtube';
            } else if ($(this).hasClass('fa-pinterest-square')) {
                $sharePlatform = 'pinterest';
            } else if ($(this).hasClass('fa-rss-square')) {
                $sharePlatform = 'rss';
            }

            dataLayer.push({
                event: 'socialShare',
                sharePlatform: $sharePlatform,
                shareType: 'general'
            });
        });

        $('.top-footer a').on('click', function () {
            var child = $(this).children('i');
            var $sharePlatform = null;
            if (child.length > 0) {
                if ($(child).hasClass('fa-instagram')) {
                    $sharePlatform = 'instagram';
                } else if ($(child).hasClass('fa-facebook-square')) {
                    $sharePlatform = 'facebook';
                } else if ($(child).hasClass('fa-twitter-square')) {
                    $sharePlatform = 'twitter';
                } else if ($(child).hasClass('fa-youtube')) {
                    $sharePlatform = 'youtube';
                } else if ($(child).hasClass('fa-pinterest-square')) {
                    $sharePlatform = 'pinterest';
                } else if ($(child).hasClass('fa-rss-square')) {
                    $sharePlatform = 'rss';
                }
                dataLayer.push({
                    event: 'socialShare',
                    sharePlatform: $sharePlatform,
                    shareType: 'general'
                });
            }
        });
    },

    formOpener: function () {
        $('body').on('formOpener:open', function (e, formName = '') {
            if (formName == '') {
                formName = window.location.href;
            }
            dataLayer.push({
                event: 'formOpen',
                formName: formName
            });
        })
    },
    gtmPush: function () {
        $('body').on('gtm:push', function (e, data) {
            if (pageData && data.pageType) {
                data.pageType = pageData.pageType;
            }
            if (data.deliverySelection) {
                if (data.deliverySelection == 'ShipToStore') {
                    data.deliverySelection = 'ship to store';
                } else if (data.deliverySelection == 'PickUpAtStore') {
                    data.deliverySelection = 'store pickup';
                } else {
                    data.deliverySelection = 'ship to home';
                }
            }
            dataLayer.push(data);
        });
    },
    formSubmit: function () {
        $('body').on('formSubmit:submit', function (e, data) {
            dataLayer.push({
                event: 'formSubmit',
                formName: window.location.href,
                submitResult: data ? "fail" : "success"
            });
        })
    },
    checkoutFormSubmitError: function () {
        $('body').on('checkoutFormError:submit', function (e, data) {
            var fieldErrors = [];
            var fieldName = [];

            if (!data.error.errorMessage) {
                for (var i in data.error) {
                    var num = i.lastIndexOf("_")
                    var subStrError = i.substr(num + 1);
                    fieldErrors.push(data.error[i])
                    fieldName.push(subStrError)
                }
            }

            var fieldErrorsStr = fieldErrors.join('|');
            var fieldNameStr = fieldName.join('|');

            dataLayer.push({
                event: 'formError',
                formName: data.formName,
                errorMessage: data.error.errorMessage ? data.error.errorMessage : fieldErrorsStr,
                formErrorField: data.error.errorMessage ? null : fieldNameStr
            });
        })
    },
    pdpShipType: function () {
        $('body').on('gtm:pdpShipType', function (e, data) {
            data.event = 'pdpShipType';
            dataLayer.push(data);
        });
    },
    pdpSelectStore: function () {
        $('body').on('gtm:pdpSelectStore', function (e, data) {
            data.event = 'pdpSelectStore';
            dataLayer.push(data);
        });
    },
    schematicToPDP: function () {
        $(document).on('click', '.schematic-link', function () {
            const schProduct = $(this).parent().find('.schematic-product');
            const productId = $(schProduct).data('pid');
            const productName = $(schProduct).text();
            const schProductName = $('.product-name').text();
            dataLayer.push({
                'productId': productId,
                'productName': productName,
                'pageName': schProductName,
                'event': 'schematicToPDP'
            });
        });
    }
};
